<template>
  <div class="headmenu">
    <span class="account mr24">
      <a-icon class="headIcon user mr8" type="user" />
      {{username}}
    </span>
    <a-icon class="headIcon logout" type="poweroff" @click="handleLogout"/>
  </div>
</template>

<script>
export default {
  name: 'head_menu',
  data () {
    return {
      username: JSON.parse(localStorage.getItem('userInfo'))?.username
    }
  },
  methods: {
    handleLogout () {
      this.$confirm({
        title: 'Are you sure to log out?',
        content: h => <div>After logging out, you will return to the login page. Are you sure to continue?</div>,
        okText: 'confirm',
        onOk: () => {
          localStorage.clear()
          this.$router.push('/login')
        },
        cancelText: 'cancel',
        onCancel() {
          console.log('Cancel')
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.headmenu{
  display: flex;
  align-items: center;
  .account{
    display: flex;
    align-items: center;
  }
}
</style>