<template>
  <a-layout id="mainlayout" class="scrollbar">
    <!-- 左侧菜单 -->
      <a-layout-sider
        v-model="collapsed"
        :width="expendedWidth"
        :collapsedWidth="collapsedWidth"
        theme="dark"
        class="lsider"
        >
        <img src="../assets/img/icon-vesync-01.svg" :style="{height: collapsed ? '30px' : '48px'}" alt="Vesync">
        <a-menu
          mode="inline"
          theme="dark"
          :openKeys.sync="openKeys"
          v-model="selectedKeys"
          :inline-collapsed="collapsed"
          class="menu">
          <template v-for="item in menus">
            <a-sub-menu :key="item.name" v-if="item.children">
              <span slot="title"><a-icon :type="item.icon" /><span>Ticket</span></span>
              <template v-for="menu in item.children">
                <template v-if="isPermission(menu.role)">
                  <a-menu-item :key="menu.path" @click="handleGo(menu.path)">{{menu.name}}</a-menu-item>
                </template>
              </template>
            </a-sub-menu>
            <template v-else>
              <a-menu-item v-if="isPermission(item.role)" :key="item.path" @click="handleGo(item.path)">
                <a-icon :type="item.icon" />
                <span>{{item.name}}</span>
              </a-menu-item>
            </template>
          </template>
            <!-- <span slot="title"><a-icon type="dashboard" /><span>Ticket</span></span>
            <a-menu-item v-if="isService" key="ticket-list" @click="handleGo('/ticket/list', 'ticket-list')">ticket list</a-menu-item>
            <a-menu-item v-else key="ship" @click="handleGo('/ship', 'ship')">shipping information</a-menu-item>
          </a-sub-menu>
          <a-menu-item key="password" @click="handleGo('/password', 'password')">Change Password</a-menu-item>
          <a-menu-item v-if="Number(role) === 2" key="account" @click="handleGo('/account', 'account')">Account List</a-menu-item> -->
        </a-menu>
      </a-layout-sider>
      <!-- 右侧板块 -->
      <a-layout :style="{ paddingLeft: contentPaddingLeft + 'px', minHeight: '100vh' }">
        <!-- 顶部栏 -->
        <a-layout-header :style="{left: contentPaddingLeft + 'px'}" class="header">
          <a-icon class="headIcon fold" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click.native="collapsed=!collapsed" />
          <div class="flex-1"></div>
          <head-menu></head-menu>
        </a-layout-header>
        <!-- 主体内容 -->
        <a-layout-content class="content">
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
</template>

<script>
import headMenu from '@/components/head_menu.vue'
export default {
  name: 'home',
  data () {
    return {
      collapsedWidth: 80,
      expendedWidth: 260,
      collapsed: false,
      openKeys: ['Ticket'],
      selectedKeys: ['/ticket/list'],
      menus: [ // 0 customer service、 1 ERP Administrator、2 Admin
        {
          name: 'Ticket',
          icon: 'dashboard',
          children: [
            {
              name: 'ticket list',
              path: '/ticket/list',
              role: [0, 1, 2, 3, 4, 5, 6, 7]
            },
            {
              name: 'Difficult Tickets',
              path: '/ticket/difficultTickets',
              role: [2, 4, 6]
            },
            {
              name: 'shipping information',
              path: '/ship',
              role: [1, 2]
            },
            {
              name: 'Create B/N code',
              path: '/ticket/BNReplace',
              role: [2, 4, 6]
            },
            {
              name: 'Remark list',
              path: '/ticket/RemarkList',
              role: [2, 3, 4, 6]
            }
          ]
        },
        {
          name: 'Account List',
          path: '/account',
          role: [2],
          icon: 'user-add'
        },
        {
          name: 'Change Password',
          path: '/password',
          role: [0, 1, 2, 3, 4, 5, 6, 7],
          icon: 'lock'
        },
        {
          name: 'CPSC Export',
          path: '/CPSC',
          role: [2, 4, 6],
          icon: 'export'
        },
        {
          name: 'Sedgwick account',
          path: '/sedgwick-account',
          role: [2, 7],
          icon: 'user'
        },
        {
          name: 'Stock base table management',
          path: '/stock',
          role: [1, 2],
          icon: 'stock'
        },
        {
          name: '报表查询',
          path: '/report',
          role: [2, 4],
          icon: 'table'
        }
      ]
    }
  },
  computed: {
    isPermission () {
      return function (role) {
        const currRole = Number(JSON.parse(localStorage.getItem('userInfo'))?.role)
        return role.includes(currRole)
      }
    },
    contentPaddingLeft () {
      return this.collapsed ? this.collapsedWidth : this.expendedWidth
    }
  },
  created () {
    this.hightMenu(this.$route.path)
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler (to) {
  //       this.hightMenu(to.name)
  //     }
  //   }
  // },
  components: {
    headMenu
  },
  methods: {
    handleGo (path) {
      this.selectedKeys = [path]
      this.$router.push({path})
    },
    hightMenu (pathName) {
      this.selectedKeys = [pathName]
    }
  }
}
</script>